import React, { useContext, useEffect, useState } from 'react';
import HeroSection from '../components/hostel-preview/hero-section';
import OverviewSection from '../components/hostel-preview/overview-section';
import OffersAndPackages from '../components/hostel-preview/offer-package';
import VenuesAvailable from '../components/hostel-preview/venu-avaliable';
import AboutThisHotel from '../components/hostel-preview/about-hotel';
import Pricing from '../components/hostel-preview/pricing';
import FeaturesSection from '../components/hostel-preview/place-offers';
import EventType from '../components/hostel-preview/event-type';
import MapComponent from '../components/hostel-preview/location';
import { HotelContext } from '../context/HotelContext';
// import PreviewNavbar from "../components/shared/previewNavbar";
import { useParams } from 'react-router-dom';
import { getSingleVenue } from '../api/getVenue';

const HotelPreview = () => {
	const { category, id } = useParams();
	const { formData, setFormData } = useContext(HotelContext);

	const [locationDetails, setLocationDetails] = useState({
		addressLine1: '',
		addressLine2: '',
		city: '',
		area: '',
		poBox: '',
		country: '',
		mapLink: '',
		lat: null,
		lng: null,
	});
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (!category || !id) return;

				const response = await getSingleVenue({ category, id });
				setFormData(response);

				if (response && response?.overview?.locationDetails) {
					setLocationDetails({
						addressLine1:
							response.overview?.locationDetails?.addressLine1 || '',
						addressLine2:
							response.overview?.locationDetails?.addressLine2 || '',
						city: response.overview?.locationDetails?.city || '',
						area: response.overview?.locationDetails?.area || '',
						poBox: response.overview?.locationDetails?.poBox || '',
						country:
							response.overview?.locationDetails?.country || '',
						mapLink:
							response.overview?.locationDetails?.mapLink || '',
						lat: response.overview?.locationDetails?.lat || null,
						lng: response.overview?.locationDetails?.lng || null,
					});
				}

				setLoading(false);
			} catch (err) {
				setError(err);
				setLoading(false);
			}
		};

		fetchData();
	}, [category, id]);

	return (
		<div>
			{/* <PreviewNavbar /> */}
			<HeroSection data={formData?.photoVideo?.images} />

			<OverviewSection data={formData} />
			<OffersAndPackages data={formData?.offerPackage} />
			{/* <VenuesAvailable /> */}
			<AboutThisHotel data={formData?.overview} />
			<Pricing data={formData?.pricing} />
			<FeaturesSection data={formData} />
			<EventType data={formData?.overview?.additionalDetails} />
			<div id='map'>
				<MapComponent locationDetails={locationDetails} />
			</div>
		</div>
	);
};

export default HotelPreview;
