/* eslint-disable no-unused-vars */
import React from "react";
import "./App.css";
import Login from "./pages/Login";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import VenuePreview from "./pages/venue-preview";
import ProtectedRoute from "./hooks/protectRoute";
import VenuesTable from "./components/dashboard/venue-table/venue-table";
import { VenueProvider } from "./context/VenueContext";
import HotelPreview from "./pages/hotel-preview";
import DocPreview from "./pages/doc-Preview";
import { HotelProvider } from "./context/HotelContext";
import GetAllUsers from "./components/dashboard/venue-table/GetAllUsers";

const queryClient = new QueryClient();

const App = () => {
  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <QueryClientProvider client={queryClient}>
        <VenueProvider>
          <HotelProvider>
            <Router>
              <Routes>
                <Route path="/" element={<Login />} />
                {/* <Route path="/venue-table" element={<VenuesTable />} /> */}

                <Route
                  path="/venue-preview/:category/:id"
                  element={<ProtectedRoute element={VenuePreview} />}
                />
                <Route
                  path="/hotel-preview/:category/:id"
                  element={<ProtectedRoute element={HotelPreview} />}
                />
                <Route
                  path="/doc-preview/:category/:id"
                  element={<ProtectedRoute element={DocPreview} />}
                />
                <Route
                  path="/venue-table"
                  element={<ProtectedRoute element={VenuesTable} />}
                />
                <Route
                  path="/allusers"
                  element={<ProtectedRoute element={GetAllUsers} />}
                />
              </Routes>
            </Router>
          </HotelProvider>
        </VenueProvider>
      </QueryClientProvider>
    </div>
  );
};

export default App;
