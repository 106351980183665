import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HeroSection from "../components/venue-preview/hero-section";
import OverviewSection from "../components/venue-preview/overview-section";
import AboutThisHotel from "../components/venue-preview/about-hotel";
import Highlights from "../components/venue-preview/heighlight";
import OffersAndPackages from "../components/venue-preview/offer-package";
import Pricing from "../components/venue-preview/pricing";
import CapacityLayout from "../components/venue-preview/capacity";
import WhatThisPlaceOffers from "../components/venue-preview/catering-drinking";
import Facilities from "../components/venue-preview/facilities-equilment";
import EventType from "../components/venue-preview/event-type";
import GoogleMapComponent from "../components/venue-preview/location";
import { getSingleVenue } from "../api/getVenue";
import { VenueContext } from "../context/VenueContext";

const VenuePreview = () => {
  const { category, id } = useParams();
  // Extract category and id from URL
  const { formData, setFormData } = useContext(VenueContext);
  const [locationDetails, setLocationDetails] = useState({
    addressLine1: "",
    addressLine2: "",
    city: "",
    area: "",
    poBox: "",
    country: "",
    mapLink: "",
    lat: null,
    lng: null,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!category || !id) return;

        const response = await getSingleVenue({ category, id });
        setFormData(response);

        if (response && response.location) {
          setLocationDetails({
            addressLine1: response?.location?.addressLine1 || "",
            addressLine2: response?.location?.addressLine2 || "",
            city: response?.location?.city || "",
            area: response?.location?.area || "",
            poBox: response?.location?.poBox || "",
            country: response?.location?.country || "",
            mapLink: response?.location?.mapLink || "",
            lat: response?.location?.lat || null,
            lng: response?.location?.lng || null,
          });
        }

        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [category, id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading venue data: {error.message}</div>;

  return (
    <div>
      <HeroSection data={formData?.imagesAndVideos?.images} />
      <OverviewSection data={formData} locationDetails={locationDetails} />
      <AboutThisHotel data={formData?.basicDetails} />
      <Highlights data={formData?.cateringAndDrinks} />
      <OffersAndPackages data={formData?.offersAndPackages} />
      <Pricing data={formData?.pricing} />
      <CapacityLayout data={formData?.capacity} />
      <WhatThisPlaceOffers data={formData} />
      <Facilities data={formData} />
      <EventType data={formData} />
      <div id="map">
        <GoogleMapComponent data={locationDetails} />
      </div>
    </div>
  );
};

export default VenuePreview;
