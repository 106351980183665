/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { HotelContext } from '../../context/HotelContext';

const HeroSection = ({ data }) => {
    const { formData, setFormData } = useContext(HotelContext);
    const navigate = useNavigate();
    // const vendorId = localStorage.getItem('vendorId');

    // const handleSaveData = async (status = 'pending') => {
    //     try {
    //         const updatedData = {
    //             ...formData,
    //             vendorId,
    //             status
    //         };
    //         const response = await saveHotelData(updatedData);
    //         if (response.success) {
    //             toast.success('Hotel detail sent for review!');
    //             if (status === 'completed') {
    //                 setTimeout(() => {
    //                     navigate('/dashboard?hotelRegister=true');
    //                 }, 1000);
    //             }
    //         } else {
    //             toast.error(response.error || 'An error occurred. Please try again.');
    //         }
    //     } catch (error) {
    //         toast.error(error.response?.data?.error || 'An error occurred. Please try again.');
    //         console.error('Error:', error);
    //     }
    // };

    return (
        <div className="p-6">
            <div className="flex flex-wrap justify-between gap-5 items-center w-full mb-6">
                <Link to="/venue-table" className="flex items-center text-gray-600 cursor-pointer">
                    <FaArrowLeft className="mr-2" />
                    <span>Back</span>
                </Link>
                {/* <div className='flex gap-3 items-center'>
                    <button
                        className="bg-black text-white px-4 py-2 rounded-lg mr-2"
                        onClick={() => handleSaveData('completed')}
                    >
                        Submit for review
                    </button>
                    <Link
                        to="/hostel-Listing-Form"
                        className="bg-red-500 text-white px-4 py-2 rounded-lg flex items-center"
                    >
                        <AiOutlineEdit className="mr-2" /> Edit
                    </Link>
                </div> */}
            </div>
            <div className="flex justify-between flex-wrap gap-5 lg:gap-0 mb-4">
                {data[0] && (
                    <div className="relative lg:w-1/2 w-full">
                        <img
                            src={data[0]}
                            alt="Main Image"
                            className="rounded-lg w-full h-[500px] object-cover"
                        />
                    </div>
                )}
                <div className="flex flex-col justify-between lg:w-[47%] w-full">
                    {data[1] && (
                        <img
                            src={data[1]}
                            alt="Image 2"
                            className="rounded-lg mb-4 h-[250px] object-cover"
                        />
                    )}
                    <div className="flex justify-between gap-5 flex-wrap">
                        {data[2] && (
                            <img
                                src={data[2]}
                                alt="Image 3"
                                className="rounded-lg mb-4 h-[250px] w-[45%] object-cover"
                            />
                        )}
                        {data.length > 3 && (
                            <div className="h-[250px] w-[47%] bg-black bg-opacity-50 flex items-center justify-center rounded-lg text-white">
                                <span>Show all photos</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeroSection;
