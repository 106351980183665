/* eslint-disable no-unused-vars */
// GoogleMapComponent.jsx
import React, { useEffect, useState } from "react";
import extractMapCoordinates from "../../utils/extractMapCoordinates";
import MapContainer from "../shared/map-container";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: 0,
  lng: 0,
};

const GoogleMapComponent = ({ locationDetails }) => {
  // const vendorId = localStorage?.getItem("vendorId");

  const [mapLocation, setMapLocation] = useState({
    lat: 0,
    lng: 0,
  });

  
  useEffect(() => {
    if (locationDetails) {
      if (locationDetails?.lat && locationDetails?.lng) {
        setMapLocation({
          lat: locationDetails?.lat,
          lng: locationDetails?.lng,
        });
      } else if (locationDetails?.mapLink && locationDetails?.mapLink !== "") {
        const coords = extractMapCoordinates(locationDetails?.mapLink);
        if (coords) {
          setMapLocation(coords);
        } else {
          console.log("Invalid map link");
        }
      } else {
        if (
          locationDetails?.city &&
          locationDetails?.area &&
          locationDetails?.country &&
          typeof window !== "undefined"
        ) {
          const address = `${locationDetails?.city}, ${locationDetails?.area}, ${locationDetails?.country}`;
          geocodeAddress(address);
        }
      }
    }
  }, [locationDetails]);

  const geocodeAddress = (address) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === "OK" && results[0]) {
        const location = results[0].geometry.location;
        const coords = {
          lat: location.lat(),
          lng: location.lng(),
        };
        setMapLocation(coords);
      } else {
        console.log(
          "Geocode was not successful for the following reason: " + status
        );
      }
    });
  };

  return (
    <div className="py-5 lg:w-3/4 w-full px-5 border-t mx-auto">
      <h2 className="text-2xl font-bold mb-4">Where We Are Located</h2>
      <MapContainer
        isHotel={false}
        isVenue={true}
        lat={mapLocation.lat}
        lng={mapLocation.lng}
        mapLocation={mapLocation}
        setMapLocation={setMapLocation}
        draggable={false}
        showHeader={false}
      />
    </div>
  );
};

export default GoogleMapComponent;
