import React from 'react';

const DynamicSVG = ({ index, currentPage, itemsPerPage }) => {
    const itemNumber = index + 1 + (currentPage - 1) * itemsPerPage;

    return (
        <svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1804_12194)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M25 8.06678H23.7179V5.58469C23.7179 4.90026 23.1429 4.34365 22.4359 4.34365H14.1026C13.9526 4.34365 13.8077 4.29277 13.6923 4.20031L10.0244 1.24104H2.5641C1.85705 1.24104 1.28205 1.79765 1.28205 2.48209V6.82574H25V8.06678H1.28205H0V2.48209C0 1.11322 1.15 0 2.5641 0H10.2564C10.4064 0 10.5513 0.0508828 10.6667 0.14334L14.3346 3.10261H22.4359C23.85 3.10261 25 4.21582 25 5.58469V8.06678Z"
                    fill="#FC4646"
                />
                <path
                    d="M25 8.06678H23.7179V5.58469C23.7179 4.90026 23.1429 4.34365 22.4359 4.34365H14.1026C13.9526 4.34365 13.8077 4.29277 13.6923 4.20031L10.0244 1.24104H2.5641C1.85705 1.24104 1.28205 1.79765 1.28205 2.48209V6.82574H25V8.06678H1.28205H0V2.48209C0 1.11322 1.15 0 2.5641 0H10.2564C10.4064 0 10.5513 0.0508828 10.6667 0.14334L14.3346 3.10261H22.4359C23.85 3.10261 25 4.21582 25 5.58469V8.06678Z"
                    stroke="white"
                    strokeWidth="0.2"
                />
                <rect y="6" width="27" height="18" rx="2" fill="#FC4646" stroke="#FC4646" />
                <text x="50%" y="70%" fill="white" fontSize="10" fontWeight="bold" textAnchor="middle">
                    {itemNumber}
                </text>
            </g>
            <defs>
                <clipPath id="clip0_1804_12194">
                    <rect width="27" height="25" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default DynamicSVG;
