/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { FaArrowLeft } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";

const HeroSection = ({ data = [] }) => {
  // Provide a default value for data
  const navigate = useNavigate();
  const vendorId = localStorage.getItem("vendorId");
  const path = useLocation();
  const errors = localStorage.getItem("Errors");

  const handleSubmit = async () => {
    // try {
    // Update formData with vendorId and status
    // setFormData((prevData) => ({
    //   ...prevData,
    //   vendorId: vendorId,
    //   status: "completed",
    // }));

    // if (errors) {
    //   toast.error(errors.trim());
    // } else {
    //   // Log formData to verify it's correctly populated

    //   if (path.pathname?.includes("venue-preview")) {
    //     const response = await axios.post(
    //       `${base_URL}/api/venueform/createVenueForm`,
    //       { ...formData, vendorId: vendorId, status: "completed" }
    //     );

    //     if (response.data.success) {
    //       // Assuming `success` indicates a successful registration
    //       toast.success("Venue created successfully!");
    //       localStorage.removeItem("Errors");
    //       Cookies.remove("uploadedFiles");
    //       resetForm();
    //       setTimeout(() => {
    //         navigate("/dashboard?venueRegister=true"); // Redirect to the dashboard
    //       }, 1000); // Delay to allow toast message to display
    //     } else {
    //       // Handle unsuccessful venue creation
    //       toast.error("Failed to create the venue.");
    //     }
    //   } else {
    //     const response = await axios.post(
    //       `${base_URL}/api/restaurantform/createRestaurant`,
    //       { ...formData, vendorId: vendorId, status: "completed" }
    //     );

    //     if (response.data.success) {
    //       toast.success("Restaurant created successfully!");
    //       Cookies.remove("uploadedFiles");
    //       resetForm();
    //       setTimeout(() => {
    //         navigate("/dashboard?restaurantRegister=true");
    //       }, 1000); // Delay to allow toast message to display
    //     } else {
    //       console.log(response);
    //       // Handle unsuccessful restaurant creation
    //       toast.error("Failed to create the restaurant.");
    //     }
    //   }
    // }
    // } catch (error) {
    //     toast.error('An error occurred. Please try again.');
    //     console.error('Error:', error.message);
    // }
  };

  return (
    <div className="p-6">
      <div className="flex flex-wrap lg:px-20 justify-between gap-5 items-center w-full mb-6">
        <Link
          to="/venue-table"
          // to={
          //   path.pathname?.includes("/restaurant-preview")
          //     ? "/venu-restaurant-Form"
          //     : "/venu-Listing-Form"
          // }
          className="flex items-center text-gray-600 cursor-pointer"
        >
          <FaArrowLeft className="mr-2" />
          <span>Back</span>
        </Link>
        <div className="flex gap-3 items-center">
          {/* <button
            className="bg-black text-white px-4 py-2 rounded-lg mr-2"
            onClick={handleSubmit}
          >
            Submit for review
          </button> */}
          {/* <Link
            to={
              path.pathname?.includes("/restaurant-preview")
                ? "/venu-restaurant-Form"
                : "/venu-Listing-Form"
            }
            className="bg-red-500 text-white px-4 py-2 rounded-lg flex items-center"
          >
            <AiOutlineEdit className="mr-2" /> Edit
          </Link> */}
        </div>
      </div>
      <div className="flex justify-between flex-wrap gap-5 lg:gap-0 mb-4">
        {data[0] && (
          <div className="relative lg:w-1/2 w-full">
            <img
              src={data[0]}
              alt="Main Image"
              className="rounded-lg w-full h-[500px] object-cover"
            />
          </div>
        )}
        <div className="flex flex-col justify-between lg:w-[48%] w-full">
          {data[1] && (
            <img
              src={data[1]}
              alt="Image 2"
              className="rounded-lg mb-4 h-[250px] object-cover"
            />
          )}
          <div className="flex justify-between gap-5 flex-wrap">
            {data[2] && (
              <img
                src={data[2]}
                alt="Image 3"
                className="rounded-lg mb-4 h-[250px] w-[45%] object-cover"
              />
            )}
            {data.length > 3 && (
              <div className="h-[250px] w-[47%] bg-black bg-opacity-50 flex items-center justify-center rounded-lg text-white">
                <span>Show all photos</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
