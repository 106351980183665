import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { base_URL } from "../../../config/config"; // Assuming base_URL is defined
import axios from "axios";
import Navbar from "../Navbar";
import moment from "moment";

const fetchAllUsers = async ({
  page = 1,
  limit = 10,
  showRegistered = false,
  showSubscribed = false,
}) => {
  const { data } = await axios.get(
    `${base_URL}/all-users?page=${page}&limit=${limit}&showRegistered=${showRegistered}&showSubscribed=${showSubscribed}`
  );
  return data;
};
export const useAllUsers = (page, limit, showRegistered, showSubscribed) => {
  return useQuery({
    queryKey: ["allUsersadmin", page, limit, showRegistered, showSubscribed],
    queryFn: () =>
      fetchAllUsers({ page, limit, showRegistered, showSubscribed }),
    keepPreviousData: true,
  });
};

function GetAllUsers() {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [showRegistered, setShowRegistered] = useState(false);
  const [showSubscribed, setShowSubscribed] = useState(false);

  const {
    data: usersData,
    isLoading,
    isError,
  } = useAllUsers(currentPage, itemsPerPage, showRegistered, showSubscribed);

  const handleFilterChange = (type, checked) => {
    if (type === "registered") {
      setShowRegistered(checked);
      setShowSubscribed(false);
    } else if (type === "subscribed") {
      setShowSubscribed(checked);
      setShowRegistered(false);
    }
    setCurrentPage(1); // Reset to first page
  };
  return (
    <div className="overflow-x-auto px-5 flex flex-col gap-5 pb-16">
      <Navbar />
      {/* Filter Control */}
      <div className="flex items-center gap-2 mb-4">
        <label className="flex items-center gap-2 cursor-pointer">
          <input
            type="checkbox"
            checked={showRegistered}
            onChange={(e) => handleFilterChange("registered", e.target.checked)}
            className="form-checkbox h-4 w-4"
          />
          <span>Show Only Registered Vendors</span>
        </label>
        <label className="flex items-center gap-2 cursor-pointer">
          <input
            type="checkbox"
            checked={showSubscribed}
            onChange={(e) => handleFilterChange("subscribed", e.target.checked)}
            className="form-checkbox h-4 w-4"
          />
          <span>Show Only Subscribed Vendors</span>
        </label>
      </div>
      <table className="min-w-full bg-white rounded-lg w-[80%] flex flex-col gap-5 justify-between">
        <thead className="hidden md:table-header-group">
          {" "}
          {/* Hide header on mobile */}
          <tr className="text-sm text-left text-offGray font-[400] flex gap-5 items-start overflow-x-auto justify-between">
            <th className="flex-1 px-3 py-2">Name</th>
            <th className="flex-1 px-3 py-2">Email</th>
            <th className="flex-1 px-3 py-2">Phone</th>
            <th className="flex-1 px-3 py-2">type</th>
            <th className="flex-1 px-3 py-2">Joined</th>
          </tr>
        </thead>
        <tbody className="w-full">
          {usersData?.data.map((item, index) => (
            <tr
              key={index}
              className="border rounded-lg py-2 my-2 text-sm text-left text-offGray font-[400] flex gap-2 items-start justify-between"
            >
              <td className="flex-1 px-3 py-2">{item.fullName}</td>
              <td className="flex-1 px-3 py-2">{item.email}</td>
              <td className="flex-1 px-3 py-2">{item.mobileNumber || "--"}</td>
              <td className="flex-1 px-3 py-2">{item.type}</td>
              <td className="flex-1 px-3 py-2">
                {item?.date ? moment(item.date).format("DD-MM-YYYY") : "--"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isLoading && <div>Loading users...</div>}
      <div className="flex justify-center mt-4">
        <button
          onClick={() => setCurrentPage((old) => Math.max(old - 1, 1))}
          disabled={currentPage === 1}
          className="px-4 py-2 mx-1 bg-gray-200 rounded"
        >
          Previous
        </button>
        <span className="px-4 py-2">
          Page {currentPage} of {usersData?.totalPages || 1}
        </span>
        <button
          onClick={() => setCurrentPage((old) => old + 1)}
          disabled={currentPage === usersData?.totalPages}
          className="px-4 py-2 mx-1 bg-gray-200 rounded"
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default GetAllUsers;
