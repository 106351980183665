import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSingleVenue } from "../api/getVenue";
import { HotelContext } from "../context/HotelContext";
import { VenueContext } from "../context/VenueContext";
import { FaFilePdf } from "react-icons/fa";
import Modal from "../components/shared/custom-modal/custom-modal";

const DocPreview = () => {
  const { category, id } = useParams();

  const { formData, setFormData } = useContext(
    category === "Venue" || category === "Restaurant"
      ? VenueContext
      : HotelContext
  );

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isImage, setIsImage] = useState(false); // New state to check if it's an image

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!category || !id) return;

        const response = await getSingleVenue({ category, id });
        setFormData(response);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [category, id, setFormData]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading venue data: {error.message}</div>;

  const openDocument = (url) => {
    const fileExtension = url.split(".").pop().toLowerCase();
    if (fileExtension === "pdf") {
      // Use Google Docs viewer for PDF preview
      const viewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(
        url
      )}&embedded=true&cachebuster=${new Date().getTime()}`;
      setSelectedDocument(viewerUrl);
      setIsImage(false);
    } else if (["png", "jpg", "jpeg", "gif"].includes(fileExtension)) {
      // Directly set the URL for image preview
      setSelectedDocument(url);
      setIsImage(true);
    } else {
      setSelectedDocument(null);
    }
  };

  const closeDocument = () => {
    setSelectedDocument(null);
    setIsImage(false);
  };

  const renderVenueDocuments = () => {
    const { UploadDocuments } = formData || {};
    const documents = [
      { name: "Floor Plans", url: UploadDocuments?.["floorPlans"] },
      { name: "Trade License", url: UploadDocuments?.["tradeLicense"] },
      { name: "Sample Menus", url: UploadDocuments?.["sampleMenus"] },
      { name: "Brochures", url: UploadDocuments?.["brochure"] },
    ];

    if (!documents.some((doc) => doc.url)) {
      return <div>No Documents available for this venue.</div>;
    }

    return (
      <div className="pdf-preview grid lg:grid-cols-3 grid-cols-2 gap-5">
        {documents.map((doc, index) =>
          doc.url ? (
            Array.isArray(doc.url) ? (
              doc.url.map(
                (d, i) =>
                  d && (
                    <div
                      key={i}
                      className="flex flex-col items-start cursor-pointer"
                      onClick={() => openDocument(d)}
                    >
                      {d.endsWith(".pdf") ? (
                        <FaFilePdf className="text-red-500 text-[200px]" />
                      ) : (
                        <img
                          src={d}
                          alt={doc.name}
                          className="text-red-500 text-[200px]"
                        />
                      )}
                      <span className="ml-2 text-lg">{doc.name}</span>
                    </div>
                  )
              )
            ) : (
              <div
                key={index}
                className="flex flex-col items-start cursor-pointer"
                onClick={() => openDocument(doc.url)}
              >
                {doc.url.endsWith(".pdf") ? (
                  <FaFilePdf className="text-red-500 text-[200px]" />
                ) : (
                  <img
                    src={doc.url}
                    alt={doc.name}
                    className="text-red-500 text-[200px]"
                  />
                )}
                <span className="ml-2 text-lg">{doc.name}</span>
              </div>
            )
          ) : null
        )}
      </div>
    );
  };

  return (
    <div className="lg:w-3/4 w-full px-5 mx-auto py-20">
      <h3 className="text-2xl font-bold py-4">Uploaded Files</h3>
      <h3 className="text-lg font-bold py-4">Click to view Files</h3>
      {category === "Venue" || category === "Restaurant" ? (
        renderVenueDocuments()
      ) : (
        <div className="pdf-preview grid lg:grid-cols-3 grid-cols-2 gap-5">
          {formData?.overview?.additionalDetails?.tradeLicenseFile ? (
            <div
              className="flex flex-col items-center cursor-pointer"
              onClick={() =>
                openDocument(
                  formData.overview.additionalDetails.tradeLicenseFile
                )
              }
            >
              <FaFilePdf className="text-red-500 text-[200px]" />
              <span className="ml-2 text-xl">Trade License</span>
            </div>
          ) : (
            <div>No Documents available for this venue.</div>
          )}
        </div>
      )}

      {selectedDocument && (
        <Modal onClose={closeDocument} isOpen={!!selectedDocument}>
          {isImage ? (
            <img
              src={selectedDocument}
              alt="Document Preview"
              width="100%"
              height="600px"
            />
          ) : (
            <iframe
              src={selectedDocument}
              title="PDF Preview"
              width="100%"
              height="600px"
            ></iframe>
          )}
        </Modal>
      )}
    </div>
  );
};

export default DocPreview;
